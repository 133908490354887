<template>
  <div class="productList">
    <h2>Producten</h2>
    <v-data-table
        id="productList"
        :headers="headers"
        :items="products"
        item-key="id"
        disable-pagination
        hide-default-footer
        @click:row="gotoEdit"
    >
    </v-data-table>
  </div>
</template>

<script>
export default {
    "name": "ProductList",
    "components": {},
    data () {
        return {
            "headers": [
                {
                    "text": "Id",
                    "sortable": true,
                    "value": "id"
                },
                {
                    "text": "Name",
                    "sortable": true,
                    "value": "name"
                },
                {
                    "text": "Prijs",
                    "sortable": true,
                    "value": "price"
                }
            ]
        };
    },
    "methods": {
        gotoEdit (item) {
            this.$router.push({
                "name": "ProductEdit",
                "params": {"id": item.id}
            });
        }
    },
    "created" () {
        this.$store.dispatch("product/getProducts");
    },
    "computed": {
        "products" () {
            return this.$store.state.product.products;
        }
    }
};
</script>
