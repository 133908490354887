<template>
    <div class="textList">
        <h2>Teksten</h2>
        <v-data-table
            id="textList"
            :headers="headers"
            :items="texts"
            item-key="id"
            disable-pagination
            hide-default-footer
            @click:row="gotoEdit"
        ></v-data-table>
    </div>
</template>

<script>
export default {
    "name": "TextList",
    "components": {},
    data () {
        return {
            "headers": [
                {
                    "text": "Name",
                    "sortable": true,
                    "value": "label"
                },
                {
                    "text": "",
                    "value": "actions",
                    "width": "72px",
                    "sortable": false
                }
            ]
        };
    },
    "methods": {
        gotoEdit (item) {
            this.$router.push({
                "name": "TextEdit",
                "params": {"id": item.id}
            });
        }
    },
    "created" () {
        this.$store.dispatch("cms/getTexts");
    },
    "computed": {
        "texts" () {
            return this.$store.state.cms.texts;
        }
    }
};
</script>
