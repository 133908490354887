import api from "../../helpers/apiHandler";

const getDefaultState = () => ({
    "user": {}
});

const state = getDefaultState();

const mutations = {
    setUser (newState, value) {
        for (const property of Object.keys(value)) {
            newState.user[property] = value[property];
        }
    },
    apiFailure (newState, value) {
        return value;
    },
    resetState () {
        Object.assign(
            state,
            getDefaultState()
        );
    }
};

const getters = {};

const actions = {
    async getSession ({commit}) {
        await api.get(
            "v1/session",
            {
                "headers": {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                }
            }
        )
            .then((response) => {
                commit(
                    "setUser",
                    response.data.user
                );
            })
            .catch((error) => commit(
                "apiFailure",
                error.message
            ));
    },
    putUser (context, value) {
        context.commit(
            "setUser",
            value
        );
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
