<template>
  <div id="forgotPasswordPage" class="row">
    <div class="col">
      <div class="white-card cover-container d-flex p-3 mt-3 mx-auto flex-column">
        <h2 class="text-center my-4">Wachtwoord vergeten</h2>

        <form v-if="showForm" id="login-form" class="form login-form" role="form" @submit.prevent="postForm">
          <label for="inputEmail">E-mail</label>
          <input type="text" id="inputEmail" class="form-control mb-3" v-model="inputEmail" required/>

          <button type="submit" class="btn btn-primary mt-4 mb-3 w-100" id="loginButton">Wachtwoord wijzigen</button>
        </form>
        <a href="/">Voorpagina</a>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../helpers/apiHandler";

export default {
    "name": "Login",
    data () {
        return {
            "inputEmail": "",
            "showForm": true
        };
    },
    "methods": {
        throwMessage (text, type) {
            this.$store.dispatch(
                "snackbar/addSnack",
                {
                    "color": type,
                    "message": text
                }
            );
        },
        "postForm" () {
            api.post(
                "v1/auth/forgotpassword",
                {
                    "email": this.inputEmail
                },
                {
                    "content-header": "application/x-www-form-urlencoded"
                }
            )
                .then(() => {
                    this.showForm = false;
                    this.throwMessage(
                        "Er is een e-mail naar u gestuurd met een link waarmee u uw wachtwoord kunt resetten.",
                        "success"
                    );
                })
                .catch((error) => {
                    if (error.status !== 200) {
                        this.throwMessage(
                            "Het ingevoerde emailadres is niet bekend bij ons.",
                            "danger"
                        );
                    }
                });
        }
    }
};
</script>

<style lang="scss">
#forgotPasswordPage {
  text-align: center;
}
</style>
