<template>
  <div>
  </div>
</template>

<script>
export default {
    "name": "Logout",
    // eslint-disable-next-line max-statements
    mounted () {
        this.$store.commit("session/resetState");
        localStorage.removeItem("jwt");
        this.$router.push("/login");
    }
};
</script>

