<template>
  <div class="reviewEdit">

    <div v-if="review">
      <v-form
          ref="reviewForm"
          @submit.prevent="postReview">
        <h2>Review toevoegen</h2>

        <v-text-field name="name" label="Naam" v-model="review.name"/>

        <v-textarea name="content" label="Content" v-model="review.content"/>

        <v-text-field name="car" label="Auto" v-model="review.car"/>

        <v-text-field type="number" min="0" max="5" name="rating" label="Rating" v-model="review.rating"/>


        <v-btn
            color="secondary"
            class="ma-2"
            type="button"
            @click="backToReviews()"
        >
          Terug
        </v-btn>
        <v-btn
            color="primary"
            class="ma-2"
            type="submit"
        >
          Opslaan
        </v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>

export default {
    "name": "ReviewNew",
    "components": {},
    data () {
        return {
            "review": {
                "name": "",
                "content": "",
                "car": "",
                "rating": 0
            },
            "rules": {
                "required": (value) => Boolean(value) || "Verplicht veld."
            }
        };
    },
    "methods": {
        backToReviews () {
            this.$router.push({"name": "ReviewList"});
        },
        throwError (text) {
            this.$store.dispatch(
                "snackbar/addSnack",
                {
                    "color": "danger",
                    "message": text
                }
            );
        },
        updateValue (field, value) {
            this.$store.dispatch(
                "review/setReviewField",
                {field,
                    value}
            );
        },
        async postReview () {
            if (!this.$refs.reviewForm.validate()) {
                return;
            }
            await this.$store.dispatch(
                "cms/postReview",
                this.review
            ).then((response) => {
                if (response.name && response.name === "Error") {
                    throw response;
                }
                this.$store.dispatch(
                    "snackbar/addSnack",
                    {"message": "Review is opgeslagen."}
                );
                this.$router.push({"name": "ReviewList"});
            }).catch(() => {
                this.$store.dispatch(
                    "snackbar/addSnack",
                    {
                        "color": "danger",
                        "message": `Review '${this.review.name}' is NIET opgeslagen.`
                    }
                );
            });
        }
    }
};
</script>
