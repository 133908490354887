var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"import"},[_c('h2',[_vm._v("Import")]),_c('v-row',[_c('v-col',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","disabled":_vm.showCarImportProgress}},'v-btn',attrs,false),on),[_vm._v(" Auto's importeren ")])]}}]),model:{value:(_vm.carDialog),callback:function ($$v) {_vm.carDialog=$$v},expression:"carDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Weet je het zeker? ")]),_c('v-card-text',[_vm._v(" Weet je zeker dat je handmatig de auto import wil starten? ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){return _vm.importLicensePlate(false)}}},[_vm._v(" Nee ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.importLicensePlate(true)}}},[_vm._v(" Ja ")])],1)],1)],1),(_vm.showCarImportProgress)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","disabled":_vm.showNawImportProgress}},'v-btn',attrs,false),on),[_vm._v(" NAW gegevens importeren ")])]}}]),model:{value:(_vm.nawDialog),callback:function ($$v) {_vm.nawDialog=$$v},expression:"nawDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v("Weet je het zeker? ")]),_c('v-card-text',[_vm._v(" Weet je zeker dat je handmatig de naw import wil starten? ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){return _vm.importNaw(false)}}},[_vm._v(" Nee ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.importNaw(true)}}},[_vm._v(" Ja ")])],1)],1)],1),(_vm.showNawImportProgress)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","disabled":_vm.showPriceImportProgress}},'v-btn',attrs,false),on),[_vm._v(" Beurtprijzen importeren ")])]}}]),model:{value:(_vm.priceDialog),callback:function ($$v) {_vm.priceDialog=$$v},expression:"priceDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v("Weet je het zeker? ")]),_c('v-card-text',[_vm._v(" Weet je zeker dat je handmatig de beurtprijzen import wil starten? ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){return _vm.importBeurtPrijzen(false)}}},[_vm._v(" Nee ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.importBeurtPrijzen(true)}}},[_vm._v(" Ja ")])],1)],1)],1),(_vm.showPriceImportProgress)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","disabled":_vm.showJobImportProgress}},'v-btn',attrs,false),on),[_vm._v(" PlanIt producten importeren ")])]}}]),model:{value:(_vm.jobDialog),callback:function ($$v) {_vm.jobDialog=$$v},expression:"jobDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v("Weet je het zeker? ")]),_c('v-card-text',[_vm._v(" Weet je zeker dat je handmatig de planIt producten import wil starten? ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){return _vm.importPlanItJobs(false)}}},[_vm._v(" Nee ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.importPlanItJobs(true)}}},[_vm._v(" Ja ")])],1)],1)],1),(_vm.showJobImportProgress)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }