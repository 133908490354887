const state = {
    "message": "",
    "color": "green",
    "show": false
};

const mutations = {
    SET_SNACK (state, snack) {
        state.message = snack.message;
        state.color = snack.color ? snack.color : "success";
        state.show = true;
    },
    SET_SHOW (state, value) {
        state.show = value;
    }
};

const getters = {};

const actions = {
    addSnack ({commit}, snack) {
        commit(
            "SET_SNACK",
            snack
        );
    },
    setShow ({commit}, value) {
        commit(
            "SET_SHOW",
            value
        );
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
