<template>
    <div class="textEdit">
        <h2>{{ currentText.label }}</h2>
        <v-form
            ref="textForm"
            @submit.prevent="putText">
            <vue-editor
                v-if="currentText.type === 'wysiwyg'"
                :editorToolbar="customToolbar"
                v-model="content"
            ></vue-editor>

            <v-text-field v-else-if="currentText.type === 'textfield'"
                          v-model="content"></v-text-field>
            <vue-editor
                v-else
                :editorToolbar="customToolbar"
                v-model="content"
            ></vue-editor>
            <v-btn
                color="primary"
                class="ma-2"
                type="submit"
            >
                Opslaan
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import {VueEditor} from "vue2-editor";

export default {
    "name": "TextEdit",
    "components": {
        VueEditor
    },
    data () {
        return {
            "customToolbar": [
                [{"header": [false, 1, 2, 3, 4, 5, 6]}], ["bold", "italic", "underline", "strike"],
                [{"align": ""}, {"align": "center"}, {"align": "right"}, {"align": "justify"}],
                ["blockquote"],
                [{"list": "ordered"}, {"list": "bullet"}, {"list": "check"}],
                [{"indent": "-1"}, {"indent": "+1"}],
                [{"color": []}, {"background": []}],
                ["link"], ["clean"]
            ]
        };
    },
    "created" () {
        this.$store.dispatch("cms/getTexts").then(async () => {
            if (this.$route.params.id) {
                await this.$store.dispatch(
                    "cms/getTextById",
                    this.$route.params.id
                );
            }
        });
    },
    "methods": {
        updateObject (object, value) {
            const updateValue = typeof value === "undefined" ? "" : value;
            this.$store.dispatch(
                "cms/updateObject",
                {
                    object,
                    "value": updateValue
                }
            );
        },
        putText () {
            this.$store.dispatch("cms/putText")
                .then(() => {
                    this.$router.push({"name": "TextList"});
                });
        }
    },
    "computed": {
        currentText () {
            return this.$store.state.cms.currentText;
        },
        "content": {
            get () {
                return this.$store.state.cms.currentText.content;
            },
            set (value) {
                this.updateObject(
                    "currentText.content",
                    value
                );
            }
        }
    },
    beforeDestroy () {
        this.$store.dispatch("cms/resetText");
    }
};
</script>
