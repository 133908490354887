import api from "../../helpers/apiHandler";

const getDefaultState = () => ({
    "mechanics": [],
    "currentMechanic": {}
});

const state = getDefaultState();

const mutations = {
    SET_MECHANICS (state, mechanics) {
        state.mechanics = mechanics;
    },
    SET_CURRENT_MECHANIC (state, mechanic) {
        state.currentMechanic = mechanic;
    },
    SET_MECHANIC_FIELD (state, params) {
        const array = params.field.split(".");
        if (array.length > 1) {
            state.currentMechanic[array[0]][array[1]] = params.value;
        } else {
            state.currentMechanic[params.field] = params.value;
        }
    },
    RESET_CURRENT_MECHANIC (state) {
        state.currentMechanic = getDefaultState().currentMechanic;
    }
};

const getters = {
};

const actions = {
    async getMechanics ({commit}) {
        await api.get(
            "v1/mechanic",
            {
                "headers": {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                }
            }
        )
            .then((response) => {
                commit(
                    "SET_MECHANICS",
                    response.data
                );
            });
    },
    async setMechanicField ({commit}, params) {
        await commit(
            "SET_MECHANIC_FIELD",
            params
        );
    },
    // eslint-disable-next-line no-unused-vars
    saveMechanic ({commit}) {
        const mechanic = {...state.currentMechanic};
        return api.put(
            `v1/mechanic/${mechanic.id}`,
            mechanic,
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`
            }
        ).catch((error) => {
            alert(error);
        });
    },
    getMechanicById ({commit}, id) {
        // eslint-disable-next-line no-shadow
        const mechanic = state.mechanics.find((mechanic) => mechanic.id === parseInt(
            id,
            10
        ));
        commit("RESET_CURRENT_MECHANIC");
        if (mechanic) {
            commit(
                "SET_CURRENT_MECHANIC",
                mechanic
            );
        }
    },
    resetMechanic ({commit}) {
        commit("RESET_CURRENT_MECHANIC");
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
