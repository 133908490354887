// Import "babel-polyfill";

import * as Integrations from "@sentry/integrations";
import * as Sentry from "@sentry/browser";

import App from "./App";
import BootstrapVue from "bootstrap-vue";
import Vue from "vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

// Enable Sentry only for selected environments
if (process.env.VUE_APP_SENTRY === true) {
    Sentry.init({
        "dsn": "https://9f9971798bae453999bc787cd703eb87@o493015.ingest.sentry.io/5561364",
        "integrations": [
            new Integrations.Vue({
                Vue,
                "attachProps": true
            }),
            new Integrations.Dedupe()
        ],
        "environment": process.env.VUE_APP_NODE_ENV
    });
}

Vue.use(BootstrapVue);
Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
    "el": "#app",
    store,
    vuetify,
    router,
    "components": {App},
    "template": "<App/>"
}).$mount("#app");
