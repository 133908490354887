<template>
  <div class="reviewEdit">

    <div v-if="review">
      <v-form
          ref="reviewForm"
          @submit.prevent="putReview">
        <h2>Review wijzigen</h2>

        <v-text-field name="name" label="Naam" :value="review.name"
                      @change="updateValue(`name`, $event)"
        />

        <v-textarea name="content" label="Content" :value="review.content"
                    @change="updateValue(`content`, $event)"
        />

        <v-text-field name="car" label="Auto" :value="review.car"
                      @change="updateValue(`car`, $event)"
        />

        <v-text-field type="number" min="0" max="5" name="rating" label="Rating" :value="review.rating"
                      @change="updateValue(`rating`, $event)"
        />


        <v-btn
            color="secondary"
            class="ma-2"
            type="button"
            @click="backToReviews()"
        >
          Terug
        </v-btn>
        <v-btn
            color="primary"
            class="ma-2"
            type="submit"
        >
          Opslaan
        </v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>

export default {
    "name": "ReviewEdit",
    "components": {},
    data () {
        return {
            "rules": {
                "required": (value) => Boolean(value) || "Verplicht veld."
            }
        };
    },
    "computed": {
        review () {
            return this.$store.state.cms.currentReview;
        }
    },
    "methods": {
        backToReviews () {
            this.$router.push({"name": "ReviewList"});
        },
        throwError (text) {
            this.$store.dispatch(
                "snackbar/addSnack",
                {
                    "color": "danger",
                    "message": text
                }
            );
        },
        updateValue (field, value) {
            this.$store.dispatch(
                "cms/setReviewField",
                {field,
                    value}
            );
        },
        async putReview () {
            if (!this.$refs.reviewForm.validate()) {
                return;
            }
            await this.$store.dispatch(
                "cms/putReview",
                {"id": this.review.id}
            ).then((response) => {
                if (response.name && response.name === "Error") {
                    throw response;
                }
                this.$store.dispatch(
                    "snackbar/addSnack",
                    {"message": "Review is opgeslagen."}
                );
                this.$router.push({"name": "ReviewList"});
            }).catch(() => {
                this.$store.dispatch(
                    "snackbar/addSnack",
                    {
                        "color": "danger",
                        "message": `Review '${this.review.name}' is NIET opgeslagen.`
                    }
                );
            });
        }
    },
    "created" () {
        this.$store.dispatch("cms/getReviews").then(async () => {
            if (this.$route.params.id) {
                await this.$store.dispatch(
                    "cms/getReviewById",
                    this.$route.params.id
                );
            }
        });
    },
    beforeDestroy () {
        this.$store.dispatch("cms/resetReview");
    }
};
</script>
