<template>
    <div class="import">
        <h2>Import</h2>

        <v-row>
            <v-col>
                <v-dialog
                    v-model="carDialog"
                    width="500"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            :disabled="showCarImportProgress"
                        >
                            Auto's importeren
                        </v-btn>
                    </template>

                    <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                            Weet je het zeker?
                        </v-card-title>

                        <v-card-text>
                            Weet je zeker dat je handmatig de auto import wil starten?
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="secondary"
                                text
                                @click="importLicensePlate(false)"
                            >
                                Nee
                            </v-btn>
                            <v-btn
                                color="primary"
                                text
                                @click="importLicensePlate(true)"
                            >
                                Ja
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-progress-circular
                    v-if="showCarImportProgress"
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-dialog
                    v-model="nawDialog"
                    width="500"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            :disabled="showNawImportProgress"
                        >
                            NAW gegevens importeren
                        </v-btn>
                    </template>

                    <v-card>
                        <v-card-title class="text-h5 grey lighten-2">Weet je het zeker?
                        </v-card-title>

                        <v-card-text>
                            Weet je zeker dat je handmatig de naw import wil starten?
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="secondary"
                                text
                                @click="importNaw(false)"
                            >
                                Nee
                            </v-btn>
                            <v-btn
                                color="primary"
                                text
                                @click="importNaw(true)"
                            >
                                Ja
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-progress-circular
                    v-if="showNawImportProgress"
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-dialog
                    v-model="priceDialog"
                    width="500"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            :disabled="showPriceImportProgress"
                        >
                            Beurtprijzen importeren
                        </v-btn>
                    </template>

                    <v-card>
                        <v-card-title class="text-h5 grey lighten-2">Weet je het zeker?
                        </v-card-title>

                        <v-card-text>
                            Weet je zeker dat je handmatig de beurtprijzen import wil starten?
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="secondary"
                                text
                                @click="importBeurtPrijzen(false)"
                            >
                                Nee
                            </v-btn>
                            <v-btn
                                color="primary"
                                text
                                @click="importBeurtPrijzen(true)"
                            >
                                Ja
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-progress-circular
                    v-if="showPriceImportProgress"
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-dialog
                    v-model="jobDialog"
                    width="500"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            :disabled="showJobImportProgress"
                        >
                            PlanIt producten importeren
                        </v-btn>
                    </template>

                    <v-card>
                        <v-card-title class="text-h5 grey lighten-2">Weet je het zeker?
                        </v-card-title>

                        <v-card-text>
                            Weet je zeker dat je handmatig de planIt producten import wil starten?
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="secondary"
                                text
                                @click="importPlanItJobs(false)"
                            >
                                Nee
                            </v-btn>
                            <v-btn
                                color="primary"
                                text
                                @click="importPlanItJobs(true)"
                            >
                                Ja
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-progress-circular
                    v-if="showJobImportProgress"
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </v-col>
        </v-row>
    </div>


</template>

<script>
import api from "@/helpers/apiHandler";

export default {
    "name": "Import",
    "components": {},
    data () {
        return {
            "carDialog": false,
            "nawDialog": false,
            "priceDialog": false,
            "jobDialog": false,
            "showCarImportProgress": false,
            "showNawImportProgress": false,
            "showPriceImportProgress": false,
            "showJobImportProgress": false
        };
    },
    "methods": {
        async importLicensePlate (doImport) {
            this.carDialog = false;

            if (doImport) {
                this.showCarImportProgress = true;
                await api.get(
                    "v1/import/CARS",
                    {
                        "headers": {
                            "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                        }
                    }
                ).then(() => {
                    this.showCarImportProgress = false;
                });
            }
        },
        async importNaw (doImport) {
            this.nawDialog = false;

            if (doImport) {
                this.showNawImportProgress = true;
                await api.get(
                    "v1/import/NAW",
                    {
                        "headers": {
                            "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                        }
                    }
                ).then(() => {
                    this.showNawImportProgress = false;
                });
            }
        },
        async importBeurtPrijzen (doImport) {
            this.priceDialog = false;

            if (doImport) {
                this.showPriceImportProgress = true;
                await api.get(
                    "v1/import/PRICE",
                    {
                        "headers": {
                            "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                        }
                    }
                ).then(() => {
                    this.showPriceImportProgress = false;
                });
            }
        },
        async importPlanItJobs (doImport) {
            this.jobDialog = false;

            if (doImport) {
                this.showJobImportProgress = true;
                await api.get(
                    "v1/import/JOBS",
                    {
                        "headers": {
                            "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                        }
                    }
                ).then(() => {
                    this.showJobImportProgress = false;
                });
            }
        }
    },
    "created" () {
        this.$store.dispatch("product/getProducts");
    },
    "computed": {
        "products" () {
            return this.$store.state.product.products;
        }
    }
};
</script>
