import api from "../../helpers/apiHandler";

const getDefaultState = () => ({
    "makes": [],
    "years": [],
    "models": []
});

const state = getDefaultState();

const mutations = {
    SET_MAKES (state, makes) {
        state.makes = makes;
    },
    SET_MODEL_YEARS (state, years) {
        state.years = years;
    },
    SET_MODELS (state, models) {
        state.models = models;
    }
};

const getters = {
};

const actions = {
    async getMakes ({commit}) {
        await api.get(
            "v1/car/makes",
            {
                "headers": {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                }
            }
        ).then((response) => {
            commit(
                "SET_MAKES",
                response.data
            );
        });
    },
    async getModelYearsByMake ({commit}, make) {
        await api.get(
            `v1/car/modelYears/${make}`,
            {
                "headers": {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                }
            }
        ).then((response) => {
            commit(
                "SET_MODEL_YEARS",
                response.data
            );
        });
    },
    async getModelsByMake ({commit}, params) {
        await api.get(
            `v1/car/models/${params.make}`,
            {
                "headers": {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                }
            }
        ).then((response) => {
            commit(
                "SET_MODELS",
                response.data
            );
        });
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
