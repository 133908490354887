import api from "../../helpers/apiHandler";

const getDefaultState = () => ({
    "products": [],
    "currentBusinessRules": [],
    "currentProduct": {}
});

const state = getDefaultState();

const mutations = {
    SET_PRODUCTS (state, products) {
        state.products = products;
    },
    ADD_BUSINESS_RULE (state, newRule) {
        state.currentBusinessRules.push(newRule);
    },
    SET_BUSINESS_RULES (state, rules) {
        state.currentBusinessRules = rules;
    },
    SET_CURRENT_PRODUCT (state, product) {
        state.currentProduct = product;
    },
    SET_PRODUCT_FIELD (state, params) {
        const array = params.field.split(".");
        if (array.length > 1) {
            state.currentProduct[array[0]][array[1]] = params.value;
        } else {
            state.currentProduct[params.field] = params.value;
        }
    },
    RESET_CURRENT_PRODUCT (state) {
        state.currentProduct = getDefaultState().currentProduct;
    },
    RESET_CURRENT_BUSINESS_RULES (state) {
        state.currentBusinessRules = getDefaultState().currentBusinessRules;
    },
    REMOVE_RULE (state, id) {
        const index = state.currentBusinessRules.findIndex((item) => item.id === id);
        state.currentBusinessRules.splice(
            index,
            1
        );
    }
};

const getters = {};

const actions = {
    async getProducts ({commit}) {
        await api.get(
            "v1/product",
            {
                "headers": {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                }
            }
        )
            .then((response) => {
                commit(
                    "SET_PRODUCTS",
                    response.data
                );
            });
    },
    async setProductField ({commit}, params) {
        await commit(
            "SET_PRODUCT_FIELD",
            params
        );
    },
    // eslint-disable-next-line no-unused-vars
    saveProduct ({commit}) {
        const product = {...state.currentProduct};
        return api.put(
            `v1/product/${product.id}`,
            product,
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`
            }
        ).catch((error) => {
            alert(error);
        });
    },
    getProductById ({commit}, id) {
        // eslint-disable-next-line no-shadow
        const product = state.products.find((product) => product.id === parseInt(
            id,
            10
        ));
        commit("RESET_CURRENT_PRODUCT");
        if (product) {
            commit(
                "SET_CURRENT_PRODUCT",
                product
            );
        }
    },
    resetProduct ({commit}) {
        commit("RESET_CURRENT_PRODUCT");
    },
    addBusinessRule ({commit}, params) {
        const productId = params.productId;
        delete params.productId;
        return api.post(
            `v1/product/${productId}/rule`,
            params,
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`
            }
        ).then(async (response) => {
            await commit(
                "ADD_BUSINESS_RULE",
                response.data
            );
        }).catch((error) => {
            alert(error);
        });
    },
    resetRules ({commit}) {
        commit("RESET_CURRENT_BUSINESS_RULES");
    },
    async getRules ({commit}, productId) {
        await api.get(
            `v1/product/${productId}/rules`,
            {
                "headers": {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                }
            }
        )
            .then((response) => {
                commit(
                    "SET_BUSINESS_RULES",
                    response.data
                );
            });
    },
    async removeRule ({commit}, id) {
        await api.delete(
            `v1/product/rule/${id}`,
            {},
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`

            }
        )
            .then(() => {
                commit(
                    "REMOVE_RULE",
                    id
                );
            });
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
