<template>
    <div id="loginPage" class="row">
        <div class="col">
            <div class="white-card cover-container d-flex p-3 mt-3 mx-auto flex-column">
                <h2 class="text-center my-4">Login</h2>

                <p id="alertbox" class="alert alert-danger p-2" v-show="error">{{ errorMessage }}</p>

                <form id="login-form" class="form login-form" role="form" @submit.prevent="postForm">
                    <label for="inputEmail">E-mail</label>
                    <input type="text" id="inputEmail" class="form-control mb-3" v-model="inputEmail" required/>

                    <label for="inputPassword">Wachtwoord</label>
                    <input type="password" id="inputPassword" class="form-control" v-model="inputPassword" required/>

                    <button type="submit" class="btn btn-primary mt-4 mb-3 w-100" id="loginButton">Inloggen</button>
                    <a href="/forgotpassword">Wachtwoord vergeten</a>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import api from "../helpers/apiHandler";

export default {
    "name": "Login",
    data () {
        return {
            "inputEmail": "",
            "inputPassword": "",
            "error": false,
            "errorMessage": "Uw emailadres en/of wachtwoord zijn niet correct, probeer het nogmaals"
        };
    },
    "methods": {
        "postForm" () {
            this.error = false;
            api.post(
                "v1/auth/login",
                {
                    "email": this.inputEmail,
                    "password": this.inputPassword
                },
                {
                    "content-header": "application/x-www-form-urlencoded"
                }
            )
                .then((response) => {
                    this.$store.dispatch(
                        "session/putUser",
                        response.data.user
                    );
                    localStorage.setItem(
                        "jwt",
                        response.data.user.token
                    );
                    this.$router.push({ "name": "Home" });
                })
                .catch((error) => {
                    if (error.status !== 200) {
                        this.error = true;
                    }
                });
        }
    }
};
</script>

<style lang="scss">
    #loginPage {
        text-align: center;
    }
</style>
