<template>
  <div id="resetPasswordPage" class="row">
    <div class="col">
      <div class="white-card cover-container d-flex p-3 mt-3 mx-auto flex-column">
        <h2 class="text-center my-4">Wachtwoord wijzigen</h2>

        <form v-if="showForm" id="login-form" class="form login-form" role="form" @submit.prevent="postForm">
          <label for="inputNewPassword">Nieuw wachtwoord</label>
          <input type="password" id="inputNewPassword" class="form-control mb-3" v-model="inputNewPassword" required/>

          <label for="inputVerifyPassword">Bevestig wachtwoord</label>
          <input type="password" id="inputVerifyPassword" class="form-control mb-3" v-model="inputVerifyPassword"
                 required/>

          <button type="submit" class="btn btn-primary mt-4 mb-3 w-100" id="loginButton">Wachtwoord wijzigen</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../helpers/apiHandler";

export default {
    "name": "Login",
    data () {
        return {
            "inputNewPassword": "",
            "inputVerifyPassword": "",
            "showForm": false
        };
    },
    created () {
        if (!this.$route.query.token) {
            this.$router.push("/");
        } else {
            api.get(
                `v1/auth/checktoken?token=${this.$route.query.token}`,
                {},
                {
                    "content-header": "application/x-www-form-urlencoded"
                }
            )
                .then((response) => {
                    if (response.data.valid) {
                        this.showForm = true;
                    }
                })
                .catch((error) => {
                    if (error.status !== 200) {
                        this.throwMessage(
                            "De link is verlopen.",
                            "danger"
                        );
                    }
                });
        }
    },
    "methods": {
        throwMessage (text, type) {
            this.$store.dispatch(
                "snackbar/addSnack",
                {
                    "color": type,
                    "message": text
                }
            );
        },
        "postForm" () {
            if (this.inputNewPassword !== this.inputVerifyPassword) {
                this.throwMessage(
                    "De wachtwoorden komen niet overeen.",
                    "danger"
                );
            } else {
                api.post(
                    `v1/auth/resetpassword?token=${this.$route.query.token}`,
                    {
                        "newPassword": this.inputNewPassword,
                        "verifyPassword": this.inputVerifyPassword
                    },
                    {
                        "content-header": "application/x-www-form-urlencoded"
                    }
                )
                    .then(() => {
                        this.throwMessage(
                            "Uw wachtwoord is succesvol gewijzigd. Klik <a href=\"/login\">hier</a> om in te loggen.",
                            "success"
                        );
                    })
                    .catch((error) => {
                        if (error.status !== 200) {
                            this.throwMessage(
                                "De link is verlopen.",
                                "danger"
                            );
                        }
                    });
            }
        }
    }
};
</script>

<style lang="scss">
#forgotPasswordPage {
  text-align: center;
}
</style>
