import * as _ from "lodash";
import api from "../../helpers/apiHandler";

const getDefaultState = () => ({
    "reviews": [],
    "currentReview": {},
    "texts": [],
    "currentText": {}
});

const state = getDefaultState();

const mutations = {
    SET_REVIEWS (state, reviews) {
        state.reviews = reviews;
    },
    SET_CURRENT_REVIEW (state, review) {
        state.currentReview = review;
    },
    UPDATE_OBJECT (state, data) {
        _.set(
            state,
            data.object,
            data.value
        );
    },
    SET_REVIEW_FIELD (state, params) {
        const array = params.field.split(".");
        if (array.length > 1) {
            state.currentReview[array[0]][array[1]] = params.value;
        } else {
            state.currentReview[params.field] = params.value;
        }
    },
    RESET_CURRENT_REVIEW (state) {
        state.currentReview = getDefaultState().currentReview;
    },
    RESET_CURRENT_TEXT (state) {
        state.currentText = getDefaultState().currentText;
    },
    REMOVE_REVIEW (state, id) {
        const index = state.reviews.findIndex((item) => item.id === id);
        state.reviews.splice(
            index,
            1
        );
    },
    SET_TEXTS (state, texts) {
        state.texts = texts;
    },
    SET_CURRENT_TEXT (state, text) {
        state.currentText = text;
    },
    UPDATE_CURRENT_TEXT_CONTENT (state, content) {
        state.currentText.content = content;
        const index = state.texts.findIndex((item) => item.id === state.currentText.id);
        state.texts[index] = state.currentText;
    }
};

const getters = {};

const actions = {
    async getReviews ({commit}) {
        await api.get(
            "v1/review",
            {
                "headers": {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                }
            }
        )
            .then((response) => {
                commit(
                    "SET_REVIEWS",
                    response.data
                );
            });
    },
    // eslint-disable-next-line no-unused-vars
    postReview ({commit}, review) {
        return api.post(
            "v1/review",
            review,
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`
            }
        ).catch((error) => {
            alert(error);
        });
    },
    // eslint-disable-next-line no-unused-vars
    putReview ({commit}) {
        const review = {...state.currentReview};
        return api.put(
            `v1/review/${review.id}`,
            review,
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`
            }
        ).catch((error) => {
            alert(error);
        });
    },
    async deleteReview ({commit}, id) {
        await api.delete(
            `v1/review/${id}`,
            {},
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`

            }
        )
            .then(() => {
                commit(
                    "REMOVE_REVIEW",
                    id
                );
            });
    },
    getReviewById ({commit}, id) {
        // eslint-disable-next-line no-shadow
        const review = state.reviews.find((review) => review.id === parseInt(
            id,
            10
        ));
        commit("RESET_CURRENT_REVIEW");
        if (review) {
            commit(
                "SET_CURRENT_REVIEW",
                review
            );
        }
    },
    async setReviewField ({commit}, params) {
        await commit(
            "SET_REVIEW_FIELD",
            params
        );
    },
    async updateObject ({commit}, params) {
        await commit(
            "UPDATE_OBJECT",
            params
        );
    },
    resetReview ({commit}) {
        commit("RESET_CURRENT_REVIEW");
    },
    async getTexts ({commit}) {
        await api.get(
            "v1/cms/text",
            {
                "headers": {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                }
            }
        )
            .then((response) => {
                commit(
                    "SET_TEXTS",
                    response.data
                );
            });
    },
    // eslint-disable-next-line no-unused-vars
    putText ({commit}) {
        const text = {...state.currentText};
        return api.put(
            `v1/cms/text/${text.id}`,
            text,
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`
            }
        )
            .catch((error) => {
                alert(error);
            });
    },
    getTextById ({commit}, id) {
        // eslint-disable-next-line no-shadow
        const text = state.texts.find((text) => text.id === parseInt(
            id,
            10
        ));
        commit("RESET_CURRENT_TEXT");
        if (text) {
            commit(
                "SET_CURRENT_TEXT",
                text
            );
        }
    },
    resetText ({commit}) {
        commit("RESET_CURRENT_TEXT");
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
