<template>
  <v-app>
    <v-navigation-drawer app v-if="isLoggedIn()"
                         permanent
    >

      <v-list
          dense
          nav
      >
        <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <v-snackbar
            v-model="showSnackbar"
            top
            :timeout="this.$store.state.snackbar.timeout"
            :color="this.$store.state.snackbar.color"
        >
          {{ this.$store.state.snackbar.message }}
          <v-icon @click="hideSnackbar">mdi-close</v-icon>
        </v-snackbar>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
    "name": "App",
    "methods": {
        isLoggedIn () {
            return localStorage.getItem("jwt") !== null;
        },
        hideSnackbar () {
            this.$store.dispatch(
                "snackbar/setShow",
                false
            );
            return true;
        }
    },
    data () {
        return {
            "items": [
                {"title": "Dashboard",
                    "link": "/",
                    "icon": "mdi-view-dashboard"},
                {"title": "Producten",
                    "link": "/products",
                    "icon": "mdi-image"},
                {"title": "Reviews",
                    "link": "/reviews",
                    "icon": "mdi-card-text-outline"},
                {"title": "Teksten",
                    "link": "/text",
                    "icon": "mdi-text"},
                {"title": "Import",
                    "link": "/import",
                    "icon": "mdi-import"},
                {"title": "Settings",
                    "link": "/settings",
                    "icon": "mdi-cog"},
                {"title": "Uitloggen",
                    "link": "/logout",
                    "icon": "mdi-logout"}
            ],
            "right": null
        };
    },
    "computed": {
        "showSnackbar": {
            get () {
                return this.$store.state.snackbar.show;
            },
            set (newValue) {
                this.$store.dispatch(
                    "snackbar/setShow",
                    newValue
                );
            }
        }
    }
};
</script>
<style lang="scss">
@import "scss/_bootstrap-defaults.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "scss/app.scss";
</style>
