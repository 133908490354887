<template>
    <div class="settings">
        <h2>Instellingen (volvo)</h2>

        <v-row v-for="setting in settings" :key="setting.id">
            <v-col>
                <v-text-field v-if="setting.type === `string`"
                              type="text"
                              :label="setting.label"
                              :name="setting.name"
                              v-model="setting.value"></v-text-field>

                <v-text-field v-if="setting.type === `int`"
                              type="number"
                              :label="setting.label"
                              :name="setting.name"
                              v-model="setting.value"></v-text-field>

                <v-menu
                    v-model="setting.menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    v-if="setting.type === `date`"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="setting.value"
                            :label="setting.label"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="setting.value"
                        @input="setting.menu = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn
                    color="primary"
                    class="ma-2"
                    type="button"
                    @click="saveSettings()"
                >
                    Opslaan
                </v-btn>
                <v-btn
                    color="warning"
                    class="ma-2"
                    type="button"
                    @click="resetSettings()"
                >
                    Reset naar standaard waardes
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import api from "@/helpers/apiHandler";
import moment from "moment";

export default {
    "name": "Settings",
    "components": {},
    data () {
        return {
            "settings": []
        };
    },
    "methods": {
        format (date) {
            if (!date) {
                return "";
            }
            return moment(
                date,
                "YYYY-MM-DD"
            ).format();
        },
        async saveSettings () {
            await api.post(
                "v1/settings",
                this.settings,
                {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                }
            ).then((response) => {
                // eslint-disable-next-line no-console
                console.log(response);
            });
        },
        async resetSettings () {
            if (!confirm("Weet je het zeker?")) {
                return;
            }
            await api.get(
                "v1/settings/reset",
                {
                    "headers": {
                        "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                    }
                }
            )
                .then(async () => {
                    await this.getSettings();
                });
        },
        async getSettings () {
            await api.get(
                "v1/settings",
                {
                    "headers": {
                        "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                    }
                }
            ).then((response) => {
                this.settings = response.data;
            });
        }
    },
    async created () {
        await this.getSettings();
    }
};
</script>
